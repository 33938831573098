import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.scss';
import Search from '@mui/icons-material/Search';
import * as AgentActions from 'utils/agent-endpoint';
import { CountryCodeList } from 'types/countries';
import { SuggestionDropdown } from 'components/agent-search';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AGENT_PAGE_NAME_SEARCH_BAR } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';
import { AgentType } from 'utils/agent-endpoint';
import CloseIcon from 'components/icon/close-icon';

interface InputWithSearchIconProps {
  onSelectName: (query: string) => void;
  country: CountryCodeList;
  nameQuery: string;
  isCommercial?: boolean;
}

export default function InputWithSearchIcon({ onSelectName, country, nameQuery, isCommercial = false }: InputWithSearchIconProps) {
  const [names, setNames] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState(nameQuery);
  const queryRef = useRef(query);

  // Watch for enter press
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && queryRef.current === '') {
        console.log('Enter pressed');
        onSelectName('');
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  useEffect(() => {
    const fetchNames = async () => {
      const agentType = isCommercial ? AgentType.COMMERCIAL : undefined;
      const data = await AgentActions.nameSuggestions(query, country, agentType);
      if (!(data instanceof Error)) {
        if (data) {
          setNames(data);
        } else {
          setNames([]);
        }
      }
    };

    if (query.length > 0) fetchNames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
    setShowSuggestions(!!value);
  };

  const handleSelectName = (n: string) => {
    onSelectName(n);
    setNames([]);
    setShowSuggestions(false);
  };

  const handleClearQuery = () => {
    setQuery('');
    setShowSuggestions(false);
    onSelectName('');
  };

  return (
    <div className={buildClassName(styles['input-wrapper'], isCommercial && styles['is-commercial'], query && styles['has-query'])}>
      <div className={styles.dropdown}>
        <div className={styles['search-button']} data-testid={agentSearchIds.agentSearchIcon}>
          <Search style={{ padding: 0, margin: 0 }} />
        </div>
        {/* name suggestion dropdown */}
        {names.length > 0 && showSuggestions && (
          <SuggestionDropdown
            suggestions={names}
            type={'name'}
            onSelect={handleSelectName}
            onClose={() => setShowSuggestions(false)}
          />
        )}
      </div>
      <input
        type="text"
        placeholder='Name'
        value={query}
        onChange={handleQueryChange}
        onClick={() => trackEvent(GTM_CLICK_AGENT_PAGE_NAME_SEARCH_BAR)}
        data-testid={agentSearchIds.agentNameInput}
      />
      {query && (
        <CloseIcon className={styles['clear-button']} onClick={handleClearQuery} />
      )}
    </div>
  );
}
